<template>
  <v-card class="pa-6">
    <template v-if="audits && audits.length && !loading">
      <v-card-title>
        <div class="flex-grow-1"></div>
        <v-text-field
          style="max-width: 400px;"
          v-model="search"
          append-icon="search"
          :label="$tc('global.buscar')"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="audits"
        :items-per-page="10"
        :sort-by="['updated_at', 'auditable_type']"
        sort-desc
        :search="search"
        class="data-tables data-tables__row-click"
        @click:row="goToAudit"
      >
        <template v-slot:item.user_id="{ item }">
          {{ getUserName(item.user_id) }}
        </template>

        <template v-slot:item.updated_at="{ item }">
          {{ item.updated_at | moment("DD/MM/YYYY, HH:mm:ss") }}
        </template>
      </v-data-table>
      <v-dialog v-model="dialog" scrollable width="700">
        <v-card class="pb-6 pt-3">
          <v-card-title class="font-weight-regular headline pt-0 px-6">
            Audit
            <v-spacer></v-spacer>
            <v-btn icon @click="dialog = false">
              <v-icon size="30">close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pt-4">
            <v-row>
              <v-col v-if="audit.user_id" cols="12" sm="6">
                {{ $tc("audits.usuariomodificou") }}:
                <v-sheet
                  class="pa-2"
                  :color="light ? 'grey lighten-4' : 'grey darken-3'"
                >
                  {{ getUserName(audit.user_id) }}
                </v-sheet>
              </v-col>

              <v-col v-if="audit.ip_address" cols="12" sm="6">
                {{ $tc("audits.ipusuario") }}:
                <v-sheet
                  class="pa-2"
                  :color="light ? 'grey lighten-4' : 'grey darken-3'"
                >
                  {{ audit.ip_address }}
                </v-sheet>
              </v-col>

              <v-col v-if="audit.user_id" cols="12" sm="6">
                {{ $tc("audits.tipoevento") }}:
                <v-sheet
                  class="pa-2"
                  :color="light ? 'grey lighten-4' : 'grey darken-3'"
                >
                  {{ audit.event }}
                </v-sheet>
              </v-col>

              <v-col v-if="audit.ip_address" cols="12" sm="6">
                {{ $tc("global.tipo") }}:
                <v-sheet
                  class="pa-2"
                  :color="light ? 'grey lighten-4' : 'grey darken-3'"
                >
                  {{ audit.auditable_type }}
                </v-sheet>
              </v-col>

              <v-col v-if="audit.ip_address" cols="12">
                {{ $tc("audits.urlusada") }}:
                <v-sheet
                  class="pa-2"
                  :color="light ? 'grey lighten-4' : 'grey darken-3'"
                >
                  {{ audit.url }}
                </v-sheet>
              </v-col>

              <v-col v-if="audit.old_values" cols="12">
                {{ $tc("audits.valorantigo") }}:
                <v-sheet
                  class="pa-2"
                  :color="light ? 'grey lighten-4' : 'grey darken-3'"
                >
                  <vue-json-pretty
                    :data="audit.old_values"
                    path="res"
                    :deep="3"
                    :collapsed-on-click-brackets="true"
                  >
                  </vue-json-pretty>
                </v-sheet>
              </v-col>

              <v-col v-if="audit.new_values" cols="12">
                {{ $tc("audits.valornovo") }}:
                <v-sheet
                  class="pa-2"
                  :color="light ? 'grey lighten-4' : 'grey darken-3'"
                >
                  <vue-json-pretty
                    :data="audit.new_values"
                    path="res"
                    :deep="3"
                    :collapsed-on-click-brackets="true"
                  >
                  </vue-json-pretty>
                </v-sheet>
              </v-col>

              <v-col v-if="audit.ip_address" cols="12" sm="6">
                Criado:
                <v-sheet
                  class="pa-2"
                  :color="light ? 'grey lighten-4' : 'grey darken-3'"
                >
                  {{ audit.created_at }}
                </v-sheet>
              </v-col>

              <v-col v-if="audit.ip_address" cols="12" sm="6">
                {{ $tc("global.atualizado") }}:
                <v-sheet
                  class="pa-2"
                  :color="light ? 'grey lighten-4' : 'grey darken-3'"
                >
                  {{ audit.updated_at }}
                </v-sheet>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>
    <NaoEncontrado v-else-if="audits && audits.length === 0" />
    <Carregando v-else-if="loading" />
  </v-card>
</template>

<script>
import AuditsApi from "@/services/api/audits.api.js";
import { getUsuarios } from "@/services/api/usuarios.api.js";
import { mapState } from "vuex";

export default {
  name: "ListaAudits",
  components: {
    VueJsonPretty: () => import("vue-json-pretty"),
  },
  data: () => ({
    search: "",
    dialog: false,
    loading: true,
    audits: null,
    audit: {},
    usuarios: [],

    auditId: null,
  }),
  computed: {
    ...mapState({
      light: (state) => state.template.light,
      dark: (state) => state.template.dark,
    }),
    headers() {
      return [
        {
          text: this.$tc("columns.id"),
          align: "center",
          value: "id",
        },
        {
          text: this.$tc("pages.usuario"),
          value: "user_id",
        },
        {
          text: "Model",
          value: "auditable_type",
        },
        {
          text: this.$tc("global.evento"),
          value: "event",
        },
        {
          text: this.$tc("audits.ipusuario"),
          align: "center",
          value: "ip_address",
        },
        {
          text: this.$tc("global.atualizacao"),
          align: "right",
          value: "updated_at",
        },
      ];
    },
  },
  methods: {
    goToAudit(item) {
      this.dialog = true;
      AuditsApi.getAudit(item.id).then((response) => {
        this.audit = response;

        this.audit.old_values = JSON.parse(this.audit.old_values);
        this.audit.new_values = JSON.parse(this.audit.new_values);
      });
    },
    getUserName(id) {
      if (id) {
        let user = this.usuarios.find((user) => user.id === id);
        if (user) {
          return user.name;
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    getAudits() {
      this.loading = true;
      this.$Progress.start();
      getUsuarios()
        .then((response) => {
          this.usuarios = response;
          AuditsApi.getAudits()
            .then((response) => {
              this.audits = response;
            })
            .catch((error) => {
              this.$Progress.fail();
              console.log(error);
            })
            .finally(() => {
              this.loading = false;
              this.$Progress.finish();
            });
        })
        .catch((error) => console.log(error));
    },
  },
  mounted() {
    this.getAudits();
  },
};
</script>

<style scoped lang="scss"></style>
