import api from "../httpClient.js";

export default {
  async getAudits() {
    const response = await api.get("/audit");
    return response.data.audit;
  },

  async getAudit(id) {
    const response = await api.get(`/audit/${id}`);
    return response.data.audit;
  }
};
